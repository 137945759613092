<template>
    <div class="order-box">
      <nav-index :title="'我的订单'"/>
      <tabs @changeTaaab="changeTaaab"/>
      <div class="order-card-block">
        <div v-if="showEmyptyImg && !orderLoading">
            <div class="none-list">
                <img src="../../assets/images/none.svg" alt="">
                <div class="none">您还没有订单喔~</div>
            </div>
        </div>
        <div v-else>
          <order-card v-for="(order,i) in orderInfo" :key="i" :orderInfo="order" :tabId="tabId" @deleteOrder="deleteOrderConfirm"
            @pay="openPayment" @received="receivedGoodsConfirm(order.oid)" @expedited="expedited" @goExpressPage="goExpressPage(order)"/>
        </div>
      </div>
      <confirm ref="confirm" @handleDelete="deleteOrder"/>
      <confirm-received ref="confirmReceived" @handleReceived="receivedGoods"/>
    </div>
</template>

<script>
import OrderCard from '../../components/card/OrderCard.vue'
import NavIndex from '../../components/nav/index.vue'
import Tabs from '../../components/tabs/index.vue'
import ConfirmReceived from '../../components/dialog/ConfirmReceived.vue'
import { queryAllOrderByUid, queryKindStatusOrderByUid, deleteOrderByOid, updateOrderByOid } from '@/api/api'
import Confirm from '../../components/dialog/Confirm.vue'
import {Toast} from 'vant'
import { getopenIdByCode } from '@/utils/index';

export default {
  name: 'Order',
  components: {
    NavIndex,
    Tabs,
    OrderCard,
    Confirm,
    ConfirmReceived,
  },
  created(){
    this.$bus.$on('changeTaaab', this.changeTaaab)
    getopenIdByCode()
  },
  activated(){
    if(this.$route.query.tabId){
      this.changeTaaab(this.$route.query.tabId)
    } else {
      this.getData('all')
    }
  },
  mounted(){
  },
  data:()=>{
    return {
      tabId: '0',
      showEmyptyImg: false,
      orderInfo:[],
      orderLoading: false,
      userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo'))[0] : {}
    }
  },
  methods:{
    getData(id){
      this.orderInfo = []
      this.showEmyptyImg = false
      this.orderLoading = true
      // 查询全部订单
      if(id === 'all'){
        queryAllOrderByUid({ uid: this.userInfo.uid }).then(res=>{
            this.orderInfo = []
            let resData = res.data.data.data
            if(resData.length > 0){
              resData.forEach((item)=>{
                let temp = {}
                temp.oid = item.oid
                temp.lists = item.lists
                temp.totalPrice = item.total_price
                temp.discountPrice = item.discount
                temp.finalPayPrice = item.finally_price
                temp.createAt = item.createdAt
                temp.status = item.order_status
                temp.waybill = item.waybill
                temp.phoneNum = item.phone_num
                temp.shop = item.shop
                this.orderInfo.push(temp)
              })
            } else {
              this.showEmyptyImg = true
            }
            this.orderLoading = false
        })
      } else {
        queryKindStatusOrderByUid({ uid: this.userInfo.uid, order_status: id }).then((res)=>{
            this.orderInfo = []
            let resData = res.data.data.data
            if(resData.length > 0){
              resData.forEach((item)=>{
                let temp = {}
                temp.oid = item.oid
                temp.imgUrl = item.imgUrl
                temp.title = item.title
                temp.type = item.type
                temp.shop = item.shop
                temp.lists = item.lists
                temp.totalPrice = item.total_price
                temp.discountPrice = item.discount
                temp.finalPayPrice = item.finally_price
                temp.createAt = item.createdAt
                temp.status = item.order_status
                temp.waybill = item.waybill
                temp.phoneNum = item.phone_num
                this.orderInfo.push(temp)
              })
            } else {
              this.showEmyptyImg = true
            }
            this.orderLoading = false
        })
      }
    },
    // updateStatus(oid){
    //   updateOrderByOid({ oid: oid, order_status: 'orderCancel' })
    // },
    changeTaaab(id){
      this.tabId = String(id)
      if(String(id) === '0'){
        this.getData('all')
      } else if(String(id) === '1'){
        this.getData('orderPaying')
      } else if(String(id) === '2'){
        this.getData('orderPicking')
      } else if(String(id) === '3'){
        this.getData('orderReceived')
      } else if(String(id) === '4'){
        this.getData('orderRefund')
      }
    },
    deleteOrderConfirm(oid){
      this.oidTemp = oid
      this.$refs.confirm.show('deleteOrder')
    },
    deleteOrder(){
      this.$refs.confirm.show('deleteOrder')
      deleteOrderByOid({ oid: this.oidTemp }).then(()=>{
        if(this.$route.query.tabId){
          this.changeTaaab(this.$route.query.tabId)
        } else {
          this.getData('all')
        }
      })
    },
    openPayment(oid){
      this.$router.push({ path:'/order-desc', query:{ oid: oid, tabId: this.tabId } })
    },
    receivedGoodsConfirm(oid){
      this.oidTemp = oid
      this.$refs.confirmReceived.show()
    },
    receivedGoods(){
      updateOrderByOid({ oid: this.oidTemp, order_status: 'orderComment' }).then(()=>{
        if(this.$route.query.tabId){
          this.changeTaaab(this.$route.query.tabId)
        } else {
          this.getData('all')
        }
      })
    },
    goExpressPage(order){
      let oid = order.oid
      let waybill = order.waybill
      let phoneNum = order.phoneNum
      this.$router.push({ path:'/express', query:{ oid:oid, waybill: waybill, phoneNum: phoneNum } })
    },
    expedited(){
      Toast('已提醒商家发货');
    }
  },
}
</script>

<style scoped lang="scss">
.order-box {
  min-height: 100vh;
  .order-card-block {
    padding: 95px 0 10px;
    padding-bottom: calc(10px + constant(safe-area-inset-bottom));
    padding-bottom: calc(10px + env(safe-area-inset-bottom));
  }
}
.none-list {
    margin: 60px auto 20px;
    width: 100%;
    max-width: 625px;
    .none {
        margin: 10px 0 0;
        color: #969699;
    }
    img {
        min-height: 142px;
    }
}
</style>